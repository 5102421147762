import { Box } from "@mui/material";
import CustomModal from "../../../components/customModal";
import React, { useEffect, useState } from "react";
import productsService from "../../../services/productsService";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

export default function HistorialModal(props) {
  const { open, setOpen, productoSKU } = props;
  const [historial, setHistorial] = useState([]);
  const [loading, setLoading] = useState(true);

  const CustomGridToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingY: "14px",
          paddingX: "16px",
        }}
      >
        <GridToolbarExport
          csvOptions={{ fileName: productoSKU + "_historial" }}
          text="Exportar"
        />
        <GridToolbarQuickFilter placeholder="Buscar..." />
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (productoSKU && open) {
      productsService.getHistory(productoSKU).then((resp) => {
        setHistorial(resp.data.Movimientos);
        setLoading(false);
      });
    }
  }, [open]);

  function handleOnClose() {
    setLoading(true);
    setHistorial([]);
    setOpen(false);
  }

  const columns = [
    { title: "Fecha", field: "Fecha", flex: 1, width: 100 },
    {
      title: "Comprobante Asociado",
      field: "Comprobante",
      flex: 1,
      width: 100,
    },
    { title: "Ingreso", field: "Ingreso", flex: 1, width: 100 },
    { title: "Egreso", field: "Egreso", flex: 1, width: 100 },
    { title: "Stock Total", field: "Stock", flex: 1, width: 100 },
  ];

  return (
    <CustomModal
      open={open}
      onClose={handleOnClose}
      title="Historial"
      disabledAutoFocus={true}
    >
      <Box sx={{ width: "100%", height: "70vh" }}>
        <DataGrid
          rows={historial}
          columns={columns}
          pageSize={5}
          loading={loading}
          disableSelectionOnClick
          slots={{ toolbar: CustomGridToolbar }}
          getRowId={(row) => historial.indexOf(row)}
          style={{
            border: "none",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            overflowY: "scroll",
            height: "100%",
          }}
          localeText={{
            toolbarExport: "Exportar",
            noRowsLabel: "No hay registros",
            toolbarExportCSV: "Exportar a CSV",
            toolbarExportPrint: "Imprimir",
          }}
        />
      </Box>
    </CustomModal>
  );
}
