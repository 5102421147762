import client from "../client/client";

const postProduct = async (data) => {
  try {
    let resp = await client.post("product-api/save", data);
    return resp;
  } catch (error) {
    console.error("Error al crear productos:", error);
    throw error;
  }
};

const putProducts = async (data) => {  
  try {
    let resp = await client.put(`product-api/update`, data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar productos:", error);
    throw error;
  }
};


const getProductsBySku = async (productoSKU) => {
  try {
    const resp = await client.get(`product-api/product/${productoSKU}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener productos por SKU:", error);
    throw error;
  }
};


const getHistory = async (sku) => {
  try {
    const resp = await client.get("productos/movimientos/?busqueda=" + sku);
    return resp;
  } catch (error) {
    console.error("Error al obtener historial:", error);
    throw error;
  }
};

let productosServices = {
  postProduct,
  putProducts,
  getProductsBySku,
  getHistory,
};

export default productosServices;
