import MaterialTable from "@material-table/core";
import * as React from "react";
import { useState } from "react";
import * as styles from "./styles";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import UserService from "../../services/userService";
import { Add, Delete } from "@mui/icons-material";
import UsuarioCard from "../mobile/usuarios/indexMobile";
import Swal from "sweetalert2";

export default function Usuarios() {
  const [isMobile, setIsMobile] = useState(false);
  const [columns, setColumns] = useState([
    { title: "Nombre", field: "user" },
    {
      title: "Contraseña",
      field: "password",
    },
    {
      title: "Rol",
      field: "role",
      lookup: {
        "Super Usuario": "Super Usuario",
        Administrativo: "Administrativo",
        Operario: "Operario",
      },
    },
  ]);

  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(false);

  const Toast = Swal.mixin({
    title: "Cargando!",
    html: "Espere la respuesta... <b></b>",
  });

  const usuario = localStorage.getItem("usuario");
  const objectUsuario = {
    user: usuario.User,
    role: usuario.Role,
    activo: 1,
  };

  const obtenerUsuarios = async (objectUsuario) => {
    const result = await UserService.getAllUsuarios(objectUsuario);
    setData(result.data.Users);
    setCargando(true);
  };

  useEffect(() => {
    if (/Mobi/.test(navigator.userAgent)) {
      // mobile!
      setIsMobile(true);
    }

    if (data.length > 0) {
    } else {
      obtenerUsuarios(objectUsuario);
    }
  }, []);

  const nuevoUsuario = async (data) => {
    setCargando(false);
    let res = await UserService.postNuevoUsuario(data);
    obtenerUsuarios(objectUsuario);

    return res;
  };

  const borrarUsuario = async (data) => {
    setCargando(false);
    let res = await UserService.deleteUsuario(data);
    obtenerUsuarios(objectUsuario);

    return res;
  };

  const UpdateUsuario = async (data) => {
    setCargando(false);
    let res = await UserService.postUpdateUsuario(data);
    obtenerUsuarios(objectUsuario);

    return res;
  };

  const handleConfirm = (data) => {
    Swal.fire({
      title: "Estas seguro que desas agregar un nuevo Usario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si , deseo agregar un nuevo usuario ",
    }).then((result) => {
      if (result.isConfirmed) {
        Toast.fire({
          icon: "success",
          title: "Enviando la peticion",
        });

        try {
          if (isMobile) {
            nuevoUsuario(data)
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    title: "Usuario agregado!",
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    title: "No se agrego el usuario !",
                    text: `ocurrio un error `,
                    icon: "error",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "No se agrego el usuario !",
                  text: `ocurrio un error ${error}`,
                  icon: "error",
                });
              });
          } else {
            let objectoNuevoUsuario = {
              rol: data.role,
              name: data.user,
              password: data.password,
            };
            nuevoUsuario(objectoNuevoUsuario)
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    title: "Usuario agregado!",
                    icon: "success",
                  });
                } else {
                  Swal.fire({
                    title: "No se agrego el usuario !",
                    text: `ocurrio un error`,
                    icon: "error",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "No se agrego el usuario !",
                  text: `ocurrio un error ${error}`,
                  icon: "error",
                });
              });
          }
        } catch (error) {
          Swal.fire({
            title: "No se agrego el usuario !",
            text: `ocurrio un error ${error}`,
            icon: "error",
          });
          obtenerUsuarios(objectUsuario);
        }
      }
    });
  };

  const handleUpdate = (data) => {
    Swal.fire({
      title: `Estas seguro que modificar el usuario?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, deseso modificar !`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Toast.fire({
          icon: "success",
          title: "Enviando la peticion",
        });
        try {
          let objectoUpdate = {
            id: data.id,
            password: data.password,
            role:
              data.role === "Operario"
                ? 1
                : data.role === "Administrativo"
                  ? 2
                  : 3,
            user: data.user,
          };
          UpdateUsuario(objectoUpdate)
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  title: `Usuario  modificado`,
                  text: `El usuario fue modificado.`,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: `Usuario NO fue modificado`,
                text: `El  usuario NO fue  modificado.`,
                icon: "error",
              });
            });
        } catch (error) {
          Swal.fire({
            title: `Usuario NO fue modificado`,
            text: `El  usuario NO fue modificado.`,
            icon: "error",
          });
          obtenerUsuarios(objectUsuario);
        }
      }
    });
  };

  const handleDelete = (e) => {
    Swal.fire({
      title: `Estas seguro que deseas ${
        e.activo === true ? "borrar" : "restablecer"
      } el usuario?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Si, deseso  ${
        e.activo === true ? "borrar" : "restablecer"
      } !`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Toast.fire({
          icon: "success",
          title: "Enviando la peticion",
        });
        try {
          const objectoUsuarioBorrar = { id: parseInt(e?.id) };
          borrarUsuario(objectoUsuarioBorrar)
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  title: `Usuario  ${
                    e.activo === true ? "borrado" : "restablecido"
                  }`,
                  text: `El usuario fue ${
                    e.activo === true ? "borrado" : "restablecido"
                  }.`,
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: `Usuario NO fue ${
                  e.activo === true ? "borrado" : "restablecido"
                }`,
                text: `El  usuario NO fue  ${
                  e.activo === true ? "borrado" : "restablecido"
                }.`,
                icon: "error",
              });
            });
        } catch (error) {
          Swal.fire({
            title: `Usuario NO fue ${
              e.activo === true ? "borrado" : "restablecido"
            }`,
            text: `El  usuario NO fue  ${
              e.activo === true ? "borrado" : "restablecido"
            }.`,
            icon: "error",
          });
          obtenerUsuarios(objectUsuario);
        }
      }
    });

    // setData([...newData]);
  };
  return isMobile === true ? (
    <UsuarioCard
      cargando={cargando}
      object={data}
      handleUpdate={handleUpdate}
      handleConfirm={handleConfirm}
      handleDelete={handleDelete}
    ></UsuarioCard>
  ) : (
    <styles.RootContainer>
      <Grid
        container
        item
        xs={10}
        justifyContent={"center"}
        style={{ alignContent: "baseline" }}
      >
        {!cargando ? (
          <div style={{ marginTop: "30%" }}>
            <Typography style={{ marginTop: "14px" }}>
              Aguarde unos segundos ...
            </Typography>
            <CircularProgress />
          </div>
        ) : (
          <Grid container item xs={12}>
            <MaterialTable
              actions={[
                (rowData) => ({
                  icon: () =>
                    rowData.activo === true ? (
                      <Delete data={rowData} />
                    ) : (
                      <Add data={rowData}></Add>
                    ),
                  tooltip: rowData.activo === true ? "Borrar" : "Restablecer",
                  onClick: (event, rowData) => handleDelete(rowData),
                }),
              ]}
              style={{ width: "100%", marginTop: "20px" }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                  searchPlaceholder: "Buscar",
                  searchTooltip: "Buscar",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Esta seguro que desea borrar este usuario",
                  },
                  editTooltip: "Editar",
                  deleteTooltip: "Eliminar",
                  addTooltip: "Agregar Usuario",
                },
              }}
              options={{
                rowStyle: {
                  overflowWrap: "break-word",
                },
                actionsColumnIndex: -1,
                //paging: false,
                search: true,
                pageSize: 5, // Cantidad de filas por página
                pageSizeOptions: [5, 10, 20], // Opciones para la cantidad de filas por página
                paginationType: "normal", // Tipo de paginación: 'normal' o 'stepped'
              }}
              title="Usuarios"
              columns={columns}
              data={data}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    handleConfirm(newData);

                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;

                    handleUpdate(dataUpdate[index]);

                    resolve();
                  }),

                //onRowDelete: (oldData) =>
                // new Promise((resolve, reject) => {
                //  setTimeout(() => {
                //   handleDelete(oldData.id);
                //  resolve();
                // }, 1000);
                // }),
              }}
            />
          </Grid>
        )}
      </Grid>
    </styles.RootContainer>
  );
}
