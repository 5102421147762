import client from "../client/client";
import { TOKEN, ROL, USER } from "../utils/constants";

// Función para decodificar un JWT
const decodeToken = (token) => {
  const payloadBase64 = token.split(".")[1]; // Extraemos la segunda parte del token (payload)
  const decodedPayload = atob(payloadBase64); // Decodificamos el Base64
  return JSON.parse(decodedPayload); // Convertimos el JSON a un objeto
};

const singIn = async (data) => {
  let resp = await client.post("/user-api/login", data);
  if (resp.status === 200 && resp.data.token) {
    const token = resp.data.token;
    const decodedToken = decodeToken(token);
    sessionStorage.setItem(TOKEN, token);
    sessionStorage.setItem(ROL, decodedToken?.roleName);
    sessionStorage.setItem(USER, decodedToken?.userID);
  }

  return resp;
};

const singOut = async () => {
  sessionStorage.clear();
};

let AuthService = { singIn, singOut };

export default AuthService;
