import {
  Grid,
  Tooltip,
  Button,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { useEffect, useState } from "react";
import ingressService from "../../../services/ingressService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as styles from "./styles";
import { formatDateString } from "../../../helpers";
import CustomModal from "../../../components/customModal";
import { Link } from "react-router-dom";
import ModalListadoIngresos from "./modalListadoIngresos";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import autoTable from "jspdf-autotable";
import CircularProgress from "@mui/material/CircularProgress";
import { returnRol } from "../../../utils/constants";
import { jsPDF } from "jspdf";
import FilterModal from "../../../components/MFilters/index.js";


const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


const initialAlert = {
  severity: "success",
  message: "",
};


const ListadoIngresos = () => {
  const [incomeList, setIncomeList] = useState([]);
  const [openDetailModal, setOpenDetailMoodal] = useState(false);
  const [ingressDetail, setIngressDetail] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [buscandoIngresos, setBuscandoIngresos] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({
    from: "2024-09-01 20:27:00",
    to: "2024-11-04 20:27:00",
    type: "in",
    status: "pending",
  });


  const [paginacion, setPaginacion] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });


  const fetchAllIngress = async (page, pageSize) => {
    setBuscandoIngresos(true);
    setOpenBackdrop(true);
    try {
      const result = await ingressService.getAllIngress({
        ...filters,
        page,
        pageSize,
      });
      setIncomeList(result?.data);
      setPaginacion((prev) => ({
        ...prev,
        total: result?.data || 0,
      }));
    } catch (error) {
      setIncomeList([]);
    } finally {
      setBuscandoIngresos(false);
      setOpenBackdrop(false);
    }
  };


  useEffect(() => {
    fetchAllIngress(paginacion.page, paginacion.pageSize);
  }, [filters]);


  const handleOpenFilterModal = () => setOpenFilterModal(true);
  const handleCloseFilterModal = () => setOpenFilterModal(false);
  const handleFilterApply = (newFilters) => setFilters(newFilters);


  const columns = [
    {
      title: "Id",
      field: "id",
      width: 40,
    },
    {
      title: "Comprobante",
      field: "receipt_id",
      maxWidth: 100,
    },
    {
      title: "Estado",
      field: "status",
      maxWidth: 110,
    },
    {
      title: "Fecha de Creacion",
      field: "date_created",
      minWidth: 150,
      render: (rowData) => formatDateString(rowData["date_created"]),
    },
    {
      title: "Articulos Recibidos",
      field: "movement_details",
      render: (rowData) => (
        <div>
          <div>SKU: {rowData.movement_details[0].product_id}</div>
          <div>Unidades: {rowData.movement_details[0].effective_count}</div>
        </div>
      ),
    },
    {
      title: "Acciones",
      field: "acciones",
      width: 30,
      render: (rowData) => (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
        >
          {rowData.status === "pending" && (
            <Tooltip title="Confirmar ingreso">
              <EditIcon
                variant="outlined"
                onClick={() => handleOpenDetailModal(rowData.id, true)}
                style={{ cursor: "pointer", color: "green" }}
              />
            </Tooltip>
          )}
          <Tooltip title="Detalle de ingreso">
            <VisibilityIcon
              variant="outlined"
              onClick={() => handleOpenDetailModal(rowData.id, false)}
              style={{ cursor: "pointer", color: "blue" }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];


  const calcularTotalUnidadesIngresadas = () => {
    let totalUnidadesIngresadas = 0;


    ingressDetail?.movement_details?.forEach((producto) => {
      totalUnidadesIngresadas += producto.effective_count || 0;
    });


    return totalUnidadesIngresadas;
  };
  const isOperario = returnRol() === "Operario";
  const sortedData = (data) => {
    if (isOperario) {
      const customOrder = {
        "Pendiente de Recibir": 1,
        "Recibido Parcialmente": 2,
        Recibido: 3,
        Cancelado: 4,
      };
      return data.sort((a, b) => {
        const orderA = customOrder[a.Estado];
        const orderB = customOrder[b.Estado];


        // Si el estado no está en el orden personalizado, colócalo al final
        const defaultOrder = Object.keys(customOrder).length + 1;


        return (orderA || defaultOrder) - (orderB || defaultOrder);
      });
    } else {
      return data.sort((a, b) => {
        const dateA = new Date(a.FechaCreacion).getTime();
        const dateB = new Date(b.FechaCreacion).getTime();
        return dateB - dateA;
      });
    }
  };
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const totalUnidadesIngresadas = calcularTotalUnidadesIngresadas();

    doc.setFont("Helvetica");

    doc.setFontSize(14);
    doc.setFont("Helvetica", "bold");
    doc.text("Detalle de Ingreso", 20, 20);
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");

    doc.setDrawColor(0);
    doc.line(15, 25, 195, 25);


    let verticalSpace = 34;
    let horizontalSpace = 20;


    function addText(text, x, y) {
      doc.text(text, x, y);
      verticalSpace += 10;
    }


    addText(
      `Fecha de Creación: ${formatDateString(ingressDetail.FechaCreacion)}`,
      horizontalSpace,
      verticalSpace
    );
    addText(
      `ID Orden: ${ingressDetail.IdOrden}`,
      horizontalSpace,
      verticalSpace
    );
    addText(
      `Comprobante: ${ingressDetail.Comprobante}`,
      horizontalSpace,
      verticalSpace
    );
    addText(
      `Contacto: ${ingressDetail.Contacto}`,
      horizontalSpace,
      verticalSpace
    );


    horizontalSpace = 110;
    verticalSpace = 34;


    addText(
      `Depósito: ${ingressDetail.Deposito}`,
      horizontalSpace,
      verticalSpace
    );
    addText(
      `Teléfono: ${ingressDetail.Telefono}`,
      horizontalSpace,
      verticalSpace
    );
    addText(`Email: ${ingressDetail.Email}`, horizontalSpace, verticalSpace);
    addText(
      `Observaciones: ${ingressDetail.Observaciones}`,
      horizontalSpace,
      verticalSpace
    );


    doc.line(15, verticalSpace, 195, verticalSpace);


    verticalSpace += 10;
    horizontalSpace = 20;


    addText(
      `Total de productos: ${ingressDetail.Detalles.length}`,
      horizontalSpace,
      verticalSpace
    );
    addText(
      `Total de unidades a ingresar: ${totalUnidadesIngresadas}`,
      horizontalSpace,
      verticalSpace
    );


    const data = ingressDetail.movement_details.map((producto) => [
      producto.product_id,
      producto.description,
      producto.original_count.toString(),
      producto.effective_count.toString(),
    ]);


    autoTable(doc, {
      startY: verticalSpace,
      head: [
        ["SKU", "Descripción", "Cantidad declarada", "Cantidad ingresada"],
      ],
      body: data,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 90 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
      },
      styles: {
        overflow: "linebreak",
      },
    });

    doc.save(`Detalle_Ingreso_${ingressDetail.IdOrden}.pdf`);
  };


  const handleOpenDetailModal = async (id, isConfirm) => {
    setOpenBackdrop(true);
    const result = await ingressService.getIngressById(id);
    setIngressDetail(result.data);
    setIsEdit(isConfirm);
    setOpenBackdrop(false);
    setOpenDetailMoodal(true);
  };


  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };


  const handleCloseDetailModal = () => setOpenDetailMoodal(false);


  return (
    <Grid container component={styles.tableContainer}>
      <Grid container item xs={12} component={styles.filtersContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenFilterModal}
        >
          Filtrar Ingresos
        </Button>
        <Link to="/crear-ingreso" style={{ marginLeft: "10px" }}>
          <Button variant="contained" color="primary">
            Crear ingreso
          </Button>
        </Link>
      </Grid>
      <Grid container item xs={8}>
        <CustomMaterialTable
          title="Listado de Ingresos"
          data={incomeList}
          columns={columns}
          style={{ width: "100%", marginTop: "20px" }}
          mensajeDataArrayVacio={
            buscandoIngresos
              ? "Buscando ingresos"
              : "No hay ingresos disponibles"
          }
          options={{
            sorting: true,
            search: true,
            paging: true,
            pageSize: paginacion.pageSize,
            debounceInterval: 500,
          }}
        />
      </Grid>
      <CustomModal
        open={openDetailModal}
        onClose={handleCloseDetailModal}
        title={isEdit ? "Confirmar ingreso" : "Detalle de Ingreso"}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "20px",
          }}
        >
          {!isEdit && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
            >
              <DownloadIcon /> Descargar pdf
            </Button>
          )}
        </Grid>
        <div
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
            padding: "20px",
          }}
        >
          <ModalListadoIngresos
            ingressDetail={ingressDetail}
            handleCloseDetailModal={handleCloseDetailModal}
            isEdit={isEdit}
            fetchAllIngress={() => fetchAllIngress(paginacion.page,paginacion.pageSize)}
            setAlertObj={setAlertObj}
            setOpenSnack={setOpenSnack}
          />
        </div>
      </CustomModal>
      <FilterModal
        open={openFilterModal}
        onClose={handleCloseFilterModal}
        onFilter={handleFilterApply}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={alertObj?.severity}
          sx={{ width: "100%" }}
        >
          {alertObj.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};


export default ListadoIngresos;

