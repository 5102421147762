
import client from "../client/client";


const postCreateIngress = async (data) => {
  const user_id = sessionStorage.getItem('User');
  const requestData = {
    ...data,
    user_id: parseInt(user_id, 10)
  };
  try {
    const resp = await client.post(`/warehouse-api/movement/save`, requestData);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};


const getAllIngress = async (params) => {
  const user_id = sessionStorage.getItem('User');
  const requestData = {
    user_id: parseInt(user_id, 10),
    from: params.from,
    to: params.to,
    page: params.page,
    pagesize: params.pageSize,
    type: params.type,
    status: params.status
  };


  try {
    const resp = await client.get("warehouse-api/movement/search", {
      params: requestData
    });
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};








const getIngressById = async (id) => {
  try {
    const resp = await client.get(`warehouse-api/movement/id/${id}`);
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos:", error);
    throw error;
  }
};


const getAllIngressPending = async () => {
  try {
    const resp = await client.get("productos/ingresosPendientes/");
    return resp;
  } catch (error) {
    console.error("Error al obtener todos los ingresos pendientes:", error);
    throw error;
  }
};


const getInventory = async (filter) => {
  try {
    const id = sessionStorage.getItem('User');
    const queryString = `?user_id=${id}${filter ? `&description=${filter}` : ""}`;
    let resp = await client.get(`product-api/search${queryString}`); // Incluye el queryString en la URL
    return resp;
   
  } catch (error) {
    console.error("Error al obtener productos:", error);
    throw error;
  }
};




const postRegisterIngress = async (data) => {
  try {
    const resp = await client.put("warehouse-api/movement/update", data);
    return resp;
  } catch (error) {
    console.error("Error al crear ingreso:", error);
    throw error;
  }
};


const ingressService = {
  postCreateIngress,
  getAllIngress,
  getIngressById,
  getAllIngressPending,
  getInventory,
  postRegisterIngress,
};

export default ingressService;