import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as styles from "./styles";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import userService from "../../../services/userService";

const initialState = {
  type: "in",
  status: "pending",
  sub_movement_id: 0,
  receipt_id: "",
  provider_name: "",
  warehouse_id: 0,
  phone_number: "",
  email: "test@test.com",
  observation: "",
};

const ModalIngreso = ({
  amountEntered,
  selectedProducts,
  handleRemoveProduct,
  cantidadIngresadaErrors,
  canConfirm,
  setAlertObj,
  setOpen,
  handleCloseModal,
  setSelectedProducts,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [depositos, setdepositos] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  useEffect(() => {
    fetchDepositos();
  }, []);

  const fetchDepositos = async () => {
    const result = await userService.getDeposits();
    setdepositos(result.data);
  };

  /*  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Ingresa un correo electrónico válido",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  }; */

  const handleConfirm = async () => {
    const requiredFields = ["comprobante", "contacto", "deposito", "telefono"];
    const newFormErrors = {};
    let hasErrors = false;

    // requiredFields.forEach((field) => {
    //   console.log("formData[field]", formData[field]);
    //   if (!formData[field].trim()) {
    //     newFormErrors[field] = "Campo requerido";
    //     hasErrors = true;
    //   }
    // });

    if (hasErrors) {
      console.log("hasErrors", hasErrors);
      console.log("newFormErrors", newFormErrors);
      setFormErrors(newFormErrors);
      return;
    }

    const hasCantidadIngresadaErrors = Object.values(
      cantidadIngresadaErrors
    ).some((error) => error !== "");

    if (hasCantidadIngresadaErrors) {
      console.log("hasCantidadIngresadaErrors", hasCantidadIngresadaErrors);
      setFormErrors({ cantidadIngresada: "Campos requeridos" });
      return;
    }

    setLoading(true);

    if (canConfirm()) {
      const requestBody = {
        ...formData,
        movement_details: selectedProducts.map((product) => ({
          product_id: product.id,
          description: product.observation,
          original_count: 0,
          effective_count: product.cantidadIngresada,
        })),
      };

      try {
        let nuevoIngreso = await ingressService.postCreateIngress(requestBody);
        if (nuevoIngreso?.data?.Registrado) {
          setAlertObj({
            severity: "success",
            message: nuevoIngreso?.data?.Registrado,
          });
          setOpen(true);
        } else if (nuevoIngreso?.data?.Error) {
          setAlertObj({
            severity: "error",
            message: nuevoIngreso?.data.Error,
          });
          setOpen(true);
        }
        generatePDF(nuevoIngreso.data.Id);
        handleCloseModal();
      } catch {
        setAlertObj({
          severity: "error",
          message: "Ocurrió un error en la creación",
        });
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const generatePDF = (id) => {
    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();

    doc.text("Detalle de Ingreso", 20, 10);
    doc.text(`Fecha de Creación: ${currentDate}`, 20, 20);
    doc.text(`ID Orden: ${id}`, 20, 30);
    doc.text(`Comprobante: ${formData.receipt_id}`, 20, 40);
    doc.text(`Contacto: ${formData.provider_name}`, 20, 50);
    doc.text(`Depósito: ${formData.warehouse_id}`, 20, 60);
    doc.text(`Teléfono: ${formData.phone_number}`, 20, 70);
    doc.text(`Email: ${formData.email}`, 20, 80);
    doc.text(`Observaciones: ${formData.observation}`, 20, 90);
    doc.text(
      `Total de unidades a ingresar: ${amountEntered}`,
      20,
      100
    );
    doc.text(`Total Sku's: ${selectedProducts.length}`, 20, 110);

    const data = selectedProducts.map((product) => [
      product.sku_id,
      product.name,
      product.cantidadIngresada.toString(),
    ]);

    // Ajustar el formato de la tabla
    autoTable(doc, {
      startY: 120,
      head: [["SKU", "Descripción", "Cantidad Ingresada"]],
      body: data,
    });

    // Guardar el PDF o abrirlo en una nueva ventana
    // Puedes ajustar el nombre del archivo según tus necesidades
    doc.save(`Detalle_Ingreso_${id}.pdf`);
    setSelectedProducts([]);
    setFormData(initialState);
    handleCloseModal();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Comprobante asociado"
            name="receipt_id"
            value={formData.receipt_id}
            onChange={handleChange}
            error={!!formErrors.receipt_id}
            helperText={formErrors.receipt_id}
          />
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Contacto"
            name="provider_name"
            value={formData.provider_name}
            onChange={handleChange}
            error={!!formErrors.provider_name}
            helperText={formErrors.provider_name}
          />
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <Grid item xs={12}>
            <FormControl sx={{ width: { xs: "100%", md: "100%" } }}>
              <InputLabel id="select-label">
                {!!formErrors.warehouse_id
                  ? formErrors.warehouse_id
                  : "Seleccione una opcion"}
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={formData.warehouse_id}
                label="Seleccione una opcion"
                name="warehouse_id"
                onChange={handleChange}
                error={!!formErrors.warehouse_id}
                helperText={formErrors.warehouse_id}
              >
                <MenuItem value="0">Seleccione una opcion</MenuItem>
                {depositos.map((element) => {
                  return (
                    <MenuItem value={element.id}>{element.name}</MenuItem>
                  );
                })}
                {/* <MenuItem value="cliente">Cliente Retira</MenuItem>
            <MenuItem value="flete">Flete Propio</MenuItem>
            <MenuItem value="redespacho">Redespacho</MenuItem>
            <MenuItem value="transporte">Transporte retira</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Telefono"
            name="phone_number"
            type="text"
            value={formData.phone_number}
            onChange={handleChange}
            error={!!formErrors.phone_number}
            helperText={formErrors.phone_number}
            inputProps={{
              pattern: "[0-9]*", // Solo permite números
              title: "Ingrese solo números",
            }}
          />
        </Grid>

        <Grid item xs={12} component={styles.InputContainer}>
          <TextField
            fullWidth
            size="small"
            label="Observaciones"
            name="observation"
            value={formData.observation}
            onChange={handleChange}
            error={!!formErrors.observation}
            helperText={formErrors.observation}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Typography>
            Total de unidades a ingresar: <b>{amountEntered}</b>
          </Typography>
          <br></br>
          <Typography>
            Total Sku's: <b>{selectedProducts.length}</b>
          </Typography>
        </Grid>
        <ListadoDeProductos
          productos={selectedProducts}
          handleRemoveProduct={handleRemoveProduct}
          withActions={true}
        />
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={handleCloseModal}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          {loading ? "Cargando..." : "Confirmar ingreso"}
        </Button>
      </Grid>
    </>
  );
};

export default ModalIngreso;
