import React from "react";
import { Typography, Grid, Button, Modal, Box, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Detalle() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [detalle, setDetalle] = useState([]);
  const [detalleId, setDetalleId] = useState("");
  const [open, setOpen] = useState(false);
  const [sku, setSku] = useState("");
  const [cantidadActual, setCantidadActual] = useState();
  const [ingresado, setIngresado] = useState(false);

  const navigate = useNavigate();

  const columns = [
    { field: "sku", headerName: "SKU", width: 100, sortable: false },
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setSku(params.row.sku);
            setOpen(true);
          }}
          sx={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "cantidad_declarada",
      headerName: "Cantidad Declarada",
      width: 80,
      sortable: false,
    },
    {
      field: "cantidad_recibida",
      headerName: "Cantidad Recibida",
      width: 80,
      sortable: false,
    },
  ];

  function parseDetalle(detalle) {
    let parsed = [];
    for (let key in detalle) {
      let row = {
        id: key,
        sku: key,
        nombre: "Producto " + key,
        cantidad_declarada: detalle[key]["Cantidad Ingresada"],
        cantidad_recibida: detalle[key]["Cantidad Recibida"],
      };
      parsed.push(row);
    }
    return parsed;
  }

  function handleGetProductoActual() {
    let producto = detalle.find((producto) => producto.sku === sku);
    return producto;
  }

  function handleSetCantidadActual() {
    let producto = handleGetProductoActual();
    if (producto) {
      producto.cantidad_recibida = cantidadActual;
    }

    let newDetalle = detalle.map((producto) => {
      if (producto.sku === sku) {
        return producto;
      }
      return producto;
    });

    setDetalle(newDetalle);
    setCantidadActual();
    setSku("");
    setOpen(false);
  }

  useEffect(() => {
    setDetalleId(params.get("id"));
    let detalle = {
      abc1: {
        "Cantidad Ingresada": 30,
        "Cantidad Recibida": null,
        "Fecha de Ingreso": null,
        Completo: false,
      },
      abc2: {
        "Cantidad Ingresada": 230,
        "Cantidad Recibida": null,
        "Fecha de Ingreso": null,
        Completo: false,
      },
      abc3: {
        "Cantidad Ingresada": 1090,
        "Cantidad Recibida": null,
        "Fecha de Ingreso": null,
        Completo: false,
      },
    };
    setDetalle(parseDetalle(detalle));
    // Services.getIngressById(params.get('id'))
    //     .then((resp) => {
    //         setDetalle(parseDetalle(resp.data))
    //     })
    //     .catch((error) => {
    //         console.error(error)
    //     })
  }, []);

  return (
    <Grid
      container
      xs={12}
      sx={{
        gap: 3,
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "center",
        paddingTop: 5,
        height: "100vh",
        width: "100%",
        paddingBottom: 40,
      }}
    >
      <Typography variant="h5">Detalle del ingreso {detalleId}</Typography>
      <Box
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: ".8rem",
              whiteSpace: "normal",
              wordWrap: "break-word",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              // Añade esta línea
              paddingRight: "10px", // Añade esta línea
            },
            "& .MuiDataGrid-menuIconButton": { display: "none" },
          }}
          rows={detalle}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
      {!ingresado ? (
        <Grid
          item
          container
          sx={{
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "center",
            width: "100%",
            gap: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "80%", height: "80px" }}
            onClick={() => setOpen(true)}
          >
            Escanear producto
          </Button>
          <Grid
            item
            container
            xs={9}
            sx={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{ width: "40%" }}
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
            <Button
              variant="outlined"
              color="primary"
              sx={{ width: "40%" }}
              onClick={() => setIngresado(true)}
              disabled={detalle.some(
                (producto) => producto.cantidad_recibida === null
              )}
            >
              Ingresar
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "80%", height: "80px" }}
          onClick={() => {
            alert("Ingreso confirmado");
            navigate("/operario");
          }}
        >
          Confirmar Ingreso
        </Button>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50vh",
            left: "50vw",
            transform: "translate(-50%, -50%)",
            width: "70%",
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <TextField
            label="Codigo de barras"
            variant="outlined"
            fullWidth
            onChange={(e) => setSku(e.target.value)}
            value={sku}
          />
          <Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Producto: {handleGetProductoActual()?.nombre}
            </Typography>

            <Grid
              container
              item
              xs={12}
              sx={{
                marginTop: 4,
                justifyContent: "space-between",
                height: "100px",
              }}
            >
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Cant. Declarada</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={handleGetProductoActual()?.cantidad_declarada}
                  disabled
                  sx={{
                    width: "100px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Cant. Recibida</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  sx={{
                    width: "100px",
                  }}
                  value={cantidadActual}
                  onChange={(e) => setCantidadActual(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              sx={{ marginTop: 4, justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setOpen(false);
                  setSku("");
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSetCantidadActual}
                disabled={!cantidadActual || !handleGetProductoActual()}
              >
                {" "}
                Confirmar
              </Button>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
}
