import { Button, Grid, TextField, Typography } from "@mui/material";
import CustomMaterialTable from "../../../components/MaterialTable";
import { formatDateString } from "../../../helpers";
import { useState } from "react";
import ListadoDeProductos from "../../../components/ListadoDeProductos";
import ingressService from "../../../services/ingressService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect } from "react";


const ModalListadoIngresos = ({
  ingressDetail,
  handleCloseDetailModal,
  isEdit,
  fetchAllIngress,
  setAlertObj,
  setOpenSnack
}) => {
  const [amountEntered, setAmountEntered] = useState({});
  const [erroresCantidad, setErroresCantidad] = useState({});
  const [inputFocus, setInputFocus] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
  }, [ingressDetail]);
 
  const handleConfirm = async () => {
    setLoading(true);
    
    const todasLasCantidadesIngresadas = ingressDetail.movement_details?.every(
      (detalle) => amountEntered[detalle.product_id] !== undefined
    );
    
    if (!todasLasCantidadesIngresadas) {
      setLoading(false);
      return;
    }
  
    const ingressData = {
      id: ingressDetail.id,
      type: ingressDetail.type,
      status: "accepted",
      sub_movement_id: ingressDetail.sub_movement_id,
      receipt_id: ingressDetail.receipt_id,
      provider_name: ingressDetail.provider_name,
      warehouse_id: ingressDetail.warehouse_id,
      email: ingressDetail.email,
      observation: ingressDetail.observation,
      phone_number: ingressDetail.phone_number,
      user_id: ingressDetail.user_id,
      movement_details: ingressDetail.movement_details.map((detalle) => ({
        product_id: detalle.product_id,
        description: detalle.description,
        original_count: parseInt(amountEntered[detalle.product_id] || 0),
        effective_count: detalle.effective_count
      }))
    };
  
    try {
      const result = await ingressService.postRegisterIngress(ingressData);
      if (result?.data) {
        setAlertObj({
          severity: "success",
          message: result.data,
        });
        handleDownloadPDF();
        setOpenSnack(true);
      } else {
        setAlertObj({
          severity: "error",
          message: result.data,
        });
        setOpenSnack(true);
      }
      fetchAllIngress();
      handleCloseDetailModal();
    } catch (error) {
      console.error("Error al registrar el ingreso", error);
    } finally {
      setLoading(false);
      handleCloseDetailModal();
    }
  };
  
  
  


  const handleQuantityChange = (event, sku) => {
    const newValue = event.target.value;

    if (!/^\d*$/.test(newValue)) {
      setErroresCantidad((prevErroresCantidad) => ({
        ...prevErroresCantidad,
        [sku]: "Solo se permiten números",
      }));
      return;
    }

    setAmountEntered((prevAmountEntered) => ({
      ...prevAmountEntered,
      [sku]: newValue,
    }));

    setErroresCantidad((prevErroresCantidad) => ({
      ...prevErroresCantidad,
      [sku]: newValue.trim() === "" ? "Ingrese la cantidad" : null,
    }));

    setInputFocus(sku);
};



  const calculateUnitsEntered = () => {
    let totalUnitsEntered = 0;


    ingressDetail?.movement_details?.forEach((producto) => {
      totalUnitsEntered += parseInt(amountEntered[producto.product_id]);
    });


    return totalUnitsEntered;
  };


  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const totalUnitsEntered = calculateUnitsEntered();


    doc.text("Detalle de Ingreso", 20, 10);
    doc.text(`Fecha de Creación: ${formatDateString(ingressDetail.FechaCreacion)}`, 20, 20);
    doc.text(`ID Orden: ${ingressDetail.id}`, 20, 30);
    doc.text(`Comprobante: ${ingressDetail.Comprobante}`, 20, 40);
    doc.text(`Contacto: ${ingressDetail.Contacto}`, 20, 50);
    doc.text(`Depósito: ${ingressDetail.warehouse_id}`, 20, 60);
    doc.text(`Teléfono: ${ingressDetail.phone_number}`, 20, 70);
    doc.text(`Email: ${ingressDetail.email}`, 20, 80);
    doc.text(`Observaciones: ${ingressDetail.description}`, 20, 90);
    doc.text(`Total de productos: ${ingressDetail.totalUnitsEntered}`, 20, 100);
    doc.text(
      `Total de unidaes a ingresar: ${totalUnitsEntered}`,
      20,
      110
    );

    const data = ingressDetail.movement_details.map((product) => [
      product.product_id,
      product.description,
      product.original_count,
      amountEntered[product.effective_count]
    ]);


    autoTable(doc, {
      startY: 120,
      head: [
        ["SKU", "Descripción", "Cantidad declarada", "Cantidad ingresada"],
      ],
      body: data,
    });


    doc.save(`Detalle_Ingreso_${ingressDetail.IdOrden}.pdf`);
  };


  const columnsProductos = [
    {
      title: "SKU",
      field: "product_id",
    },
    {
      title: "Descripcion",
      field: "description",
      maxWidth: 100,
    },
    {
      title: "Cantiad declarada",
      field: "effective_count",
      minWidth: 150,
    },
    {
      title: "Cantidad ingresada",
      field: "original_count",
      minWidth: 200,
      render: (rowData) => (
        <TextField
          variant="outlined"
          key={rowData?.product_id}
          size="small"
          type="text"
          style={{
            width: "80px",
          }}
          value={amountEntered[rowData?.product_id] || ""}
       
          onChange={(event) => handleQuantityChange(event, rowData?.product_id)}
          inputProps={{
            pattern: "[0-9]*",
            title: "Ingrese solo números",
          }}
          autoFocus={inputFocus === rowData?.product_id}
          error={erroresCantidad[rowData?.product_id] !== null}
          helperText={erroresCantidad[rowData?.product_id]}
        />
      ),
    },
  ];


  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Typography>
            Id Orden: <b>{ingressDetail?.id}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Comprobante: <b>{ingressDetail?.receipt_id}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Fecha creación: <b>{formatDateString(ingressDetail?.date_created)}</b>
          </Typography>
        </Grid>


        <Grid item xs={4}>
          <Typography>
            Contacto: <b>{ingressDetail?.provider_name}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Deposito: <b>{ingressDetail?.warehouse_id}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Telefono: <b>{ingressDetail?.phone_number}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Email: <b>{ingressDetail?.email}</b>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Observaciones: <b>{ingressDetail?.observation}</b>
          </Typography>
        </Grid>
      </Grid>


      {!isEdit ? (
        <ListadoDeProductos
          productos={ingressDetail.movement_details}
          withDeclared={true}
        />
      ) : (
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
          }}
        >
          <CustomMaterialTable
            title="Listado de Productos"
            data={ingressDetail?.movement_details.map(item => ({
              ...item,
              id: item.product_id 
            }))}
            columns={columnsProductos}
            style={{ width: "100%", margin: "20px 0px" }}
            mensajeDataArrayVacio="No hay ingresos disponibles"
          />
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseDetailModal}
            style={{ marginRight: "16px" }}
          >
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            {loading ? "Cargando..." : "Confirmar registro"}
          </Button>
        </Grid>
      )}
    </>
  );
};
export default ModalListadoIngresos;