import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import userService from "../../services/userService";
import warehouseService from "../../services/warehouseService";
import * as React from "react";

const Perfil = () => {
  const [depositos, setDepositos] = useState([]);
  const [deposito, setDeposito] = useState(null);
  const [canEditUser, setCanEditUser] = useState(false);
  const [canEditDeposito, setCanEditDeposito] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    userService.getProfile().then((res) => {
      let data = res;
      setUser(data.user);
      setDepositos(data.depositos);
      if (data.depositos.length > 0) {
        setDeposito(data.depositos[0]);
      }
    });
  }, []);

  function handleEditUser() {
    if (canEditUser) {
      userService.updatePerfil(user).then((res) => {
        console.log("Perfil actualizado", res);
      });
    }

    setCanEditUser(!canEditUser);
  }

  function handleEditDeposito() {
    if (canEditDeposito) {
      warehouseService.updateDeposito(deposito).then((res) => {
        console.log("Deposito actualizado", res);
      });
    }

    setCanEditDeposito(!canEditDeposito);
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="row"
      xs={12}
      sx={{
        gap: 10,
        paddingTop: 2,
      }}
    >
      <Grid
        container
        item
        xs={4}
        justifyContent="center"
        alignItems="flex-end"
        flexDirection="column"
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          padding: 6,
          boxShadow: 1,
          gap: 2,
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
            }}
          >
            Perfil
          </Typography>
          <TextField
            disabled={!canEditUser}
            label="User"
            name="user"
            value={user?.user}
            onChange={(e) => setUser({ ...user, user: e.target.value })}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            disabled
            label="Fecha de Creación"
            InputLabelProps={{ shrink: true }}
            value={user?.fechaCreacion}
            onChange={(e) =>
              setUser({ ...user, fechaCreacion: e.target.value })
            }
            fullWidth
          />
          <TextField
            disabled
            label="Rol"
            value={user?.rol}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setUser({ ...user, rol: e.target.value })}
            fullWidth
          />
          <TextField
            disabled
            label="Empresa"
            InputLabelProps={{ shrink: true }}
            value={user?.empresa}
            onChange={(e) => setUser({ ...user, empresa: e.target.value })}
            fullWidth
          />
          <TextField
            disabled
            label="Email"
            InputLabelProps={{ shrink: true }}
            value={user?.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            fullWidth
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditUser()}
          style={{
            width: 100,
          }}
        >
          {canEditUser ? "Guardar" : "Editar"}
        </Button>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          padding: 6,
          boxShadow: 1,
          gap: 2,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: "bold",
          }}
        >
          Depositos
        </Typography>
        <Grid
          container
          item
          xs={10}
          justifyContent="center"
          alignItems="flex-end"
          flexDirection="flex"
          sx={{
            gap: 2,
          }}
        >
          <Autocomplete
            options={depositos}
            getOptionLabel={(option) => option.nombre}
            value={deposito}
            onChange={(e, value) =>
              setDeposito(depositos.find((d) => d.id === value.id))
            }
            style={{ flex: 1 }}
            renderInput={(params) => (
              <TextField {...params} label="Depositos" />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEditDeposito()}
            style={{
              width: 100,
            }}
          >
            {canEditDeposito ? "Guardar" : "Editar"}
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={12}
          flexDirection="row"
          justifyContent="center"
          gap={2}
        >
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              gap: 2,
            }}
          >
            <TextField
              disabled={!canEditDeposito}
              label="Localidad"
              value={deposito?.localidad}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, localidad: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Provincia"
              value={deposito?.provincia}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, provincia: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Codigo Postal"
              value={deposito?.codigoPostal}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, codigoPostal: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Calle"
              value={deposito?.calle}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, calle: e.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              gap: 2,
            }}
          >
            <TextField
              disabled={!canEditDeposito}
              label="Numero"
              value={deposito?.numero}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, numero: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Piso"
              value={deposito?.piso}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, piso: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Departamento"
              value={deposito?.departamento}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, departamento: e.target.value })
              }
              fullWidth
            />
            <TextField
              disabled={!canEditDeposito}
              label="Telefono"
              value={deposito?.telefono}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDeposito({ ...deposito, telefono: e.target.value })
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Perfil;
