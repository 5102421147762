import React from 'react';
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import productsService from "../../services/productsService";
import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import * as styles from "./styles";

const initialForm = {
  sku: "", //requerido str
  nombre: "", //requerido str
  ean: "", //opcional str
  alto: "", //opcional float
  largo: "", //opcional float
  ancho: "", //opcional float
  peso: "", //opcional float
  observaciones: "", //opcional ,
}

const initialAlert = {
  severity: "success",
  message: "",
}

export default function AltaProductos() {
  const [alertObj, setAlertObj] = useState(initialAlert);
  const [open, setOpen] = useState(false);
  const [formulario, setFormulario] = useState(initialForm);
  
  const handleChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    setFormulario({ ...formulario, [key]: value });
  };

  const handleClickSubmitCancel = () => {
    setFormulario(initialForm);
    setAlertObj({
      severity: "warning",
      message: "Se limpiaron los campos",
    });
    setOpen(true)
  };

  const handleClickSubmit = async () => {
    
    try {
      let productoNuevo = await productsService.postProduct(formulario);
      if (productoNuevo?.data?.Registrado) {
        setAlertObj({
          severity: "success",
          message: productoNuevo?.data?.Registrado,
        });
        setOpen(true)
      } else if (productoNuevo?.data?.Error){
        setAlertObj({
          severity: "error",
          message: productoNuevo?.data.Error,
        });
        setOpen(true)
      }
    } catch {
      setAlertObj({
        severity: "error",
        message: "Ocurrió un error en la creación",
      });
      setOpen(true)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {}, [formulario]);

  return (
    <styles.RootContainer>
      <Grid container item xs={12} sm={10} lg={8} >
        <Box component={styles.StyledBox}>
          <h1 style={{color: '#1976d2'}}>Formulario Crear Productos</h1>
          <Grid container>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                value={formulario["nombre"]}
                error={!formulario["nombre"].length > 3}
                name="nombre"
                label="Nombre"
                helperText="Debe ingresar al menos 4 caracteres"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                value={formulario["sku"]}
                error={!formulario["sku"].length > 3}
                name="sku"
                label="Sku"
                helperText="Debe ingerar al menos 4 caracteres"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                type="number"
                inputProps={{
                  step: 0.5,
                }}
                value={formulario["alto"]}
                name="alto"
                label="alto"
                helperText="Alto del producto"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                type="number"
                inputProps={{
                  step: 0.5,
                }}
                value={formulario["largo"]}
                name="largo"
                label="Largo"
                helperText="Largo del producto"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                type="number"
                inputProps={{
                  step: 0.5,
                }}
                value={formulario["ancho"]}
                name="ancho"
                label="Ancho"
                helperText="Ancho del producto"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                type="number"
                inputProps={{
                  step: 0.5,
                }}
                value={formulario["peso"]}
                name="peso"
                label="Peso"
                helperText="Peso del producto"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                value={formulario["ean"]}
                multiline
                name="ean"
                label="Ean"
                helperText="opcional"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} md={6} component={styles.InputGridContainer}>
              <TextField
                value={formulario["descripcion"]}
                multiline
                name="descripcion"
                label="Descripcion"
                helperText="opcional"
                onChange={handleChange}
                component={styles.InputField}
              />
            </Grid>
            <Grid item xs={12} component={styles.ButtonsContainer}>
              <Button
                color="error"
                variant="contained"
                onClick={handleClickSubmitCancel}
              >
                Limpiar
              </Button>
              <Button
                disabled={
                  formulario.nombre.length > 3 &&
                  formulario.sku.length > 4
                }
                style={{backgroundColor: '#1976d2'}}
                variant="contained"
                onClick={handleClickSubmit}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertObj?.severity} sx={{ width: '100%' }}>
             {alertObj.message}
            </Alert>
          </Snackbar>
        </Box>
      </Grid>
    </styles.RootContainer>
  );
}
