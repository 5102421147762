import client from "../client/client";

const updateWarehouse = async (data) => {
  try {
    const resp = await client.put("users/depositos/", data);
    return resp;
  } catch (error) {
    console.error("Error al actualizar deposito:", error);
    throw error;
  }
};


let warehouseService = [
    updateWarehouse
];
export default warehouseService;
