import React, { useState } from "react";
import { Modal, Button, Grid, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const FilterModal = ({ open, onClose, onFilter }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("in");
  const [movementStatus, setMovementStatus] = useState("pending");

  const handleFilter = () => {
    onFilter({ from: fromDate, to: toDate, type, movementStatus });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: "white",
          padding: "20px",
          margin: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          borderRadius: "8px",
          boxShadow: "0 3px 10px rgba(0,0,0,0.2)"
        }}
      >
        <Grid item xs={12}>
          <h2>Filtrar Ingresos</h2>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Desde"
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Hasta"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Tipo</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Tipo"
            >
              <MenuItem value="in">Entrada</MenuItem>
              <MenuItem value="out">Salida</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Estado de Movimiento</InputLabel>
            <Select
              value={movementStatus}
              onChange={(e) => setMovementStatus(e.target.value)}
              label="Estado de Movimiento"
            >
              <MenuItem value="pending">Pendiente</MenuItem>
              <MenuItem value="completed">Completado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleFilter} color="primary" variant="contained">
            Aplicar Filtro
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FilterModal;
