import { styled } from "@mui/system";

export const tableContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",

}));

export const filtersContainer = styled("div")(() => ({
  paddingTop: '20px',
  justifyContent: 'end'
  // [theme.breakpoints.up("md")]: {
    
  // },
}));

export const InputGridContainer = styled("div")({
  paddingBottom: '16px',
});

export const InputField = styled("div")({
  width: "80%",
});

export const ButtonsContainer = styled("div")({
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: '16px',
});

