import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import Sidebar from "./SideBar";
import MenuUsuario from "./MenuUsuario";

const AppBarComponent = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={toggleSidebar}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" style={{ marginLeft: "4vh" }}>
              Bienvenido a Voras
            </Typography>
          </div>
          <div style={{marginRight:"10px"}}>
            <MenuUsuario />
          </div>
        </Toolbar>
      </AppBar>

      <Sidebar open={sidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default AppBarComponent;
